import React, { useEffect, useState } from "react"
import Helmet from "react-helmet"
import useSiteMetadata from "../static_queries/useSiteMetadata"
import layoutStyles from "../styles/components/layout.module.scss"
import loadable from "@loadable/component"
import AOS from "aos"
import "aos/dist/aos.css"
import "../styles/components/aos.scss"
import CookieConsent from "react-cookie-consent"
import { Link } from "gatsby"
import FeatherIcon from "feather-icons-react"
import { isMobile } from "react-device-detect"
const AnimatedCursor = loadable(() => import("../components/AnimatedCursor.js"))

export default function Layout(props) {
  const [accepted, setAccepted] = useState(false)
  const [mouseOut, setMouseOut] = useState(false)
  const { title, description } = useSiteMetadata()
  function addEvent(obj, evt, fn) {
    if (obj.addEventListener) {
      obj.addEventListener(evt, fn, false)
    } else if (obj.attachEvent) {
      obj.attachEvent("on" + evt, fn)
    }
  }

  useEffect(() => {
    let isMounted = true
    if (isMounted) {
      AOS.init({
        once: true,
      })
      addEvent(document, "mouseout", function(e) {
        e = e ? e : window.event
        var from = e.relatedTarget || e.toElement
        if (!from || from.nodeName === "HTML") {
          setMouseOut(true)
        } else {
          setMouseOut(false)
        }
      })
    }

    return () => {
      isMounted = false
      document.removeEventListener(
        "mouseout",
        function(e) {
          e = e ? e : window.event
          var from = e.relatedTarget || e.toElement
          if (!from || from.nodeName === "HTML") {
            setMouseOut(true)
          } else {
            setMouseOut(false)
          }
        },
        false
      )
    }
  }, [])

  return (
    <section
      className={`${layoutStyles.layout} ${props.page === "info" &&
        layoutStyles.info_page}`}
      style={{
        backgroundColor: props.bgColor,
      }}
      id="page"
    >
      <Helmet>
        <html lang="en" />
        <title>{title}</title>
        <meta name="description" content={description} key="description"/>
        <meta httpEquiv="Content-Type" content="text/html; charset=utf-8" key="Content-Type"/>
        <meta name="referrer" content="no-referrer-when-downgrade" key="referrer"/>
        <meta name="robots" content="all" key="robots"/>
        <meta name="keywords" content={`Ashley Van Laer, Belgium, Antwerp, Freelance, Frontend, developer, full-stack, firebase, reactjs, vuejs, website, webapp, iOS, Android`} key="keywords"/>

        {/* TWITTER */}
        <meta name="twitter:card" content="summary" key="twitter-card"/>
        <meta name="twitter:image" content="https://firebasestorage.googleapis.com/v0/b/portfolio-ashleyvanlaer.appspot.com/o/Schermafbeelding%202020-10-16%20om%2013.58.00.png?alt=media&token=fd23d559-9b93-4e3c-b5f9-502f0bde09e2" key="twitter-image"/>
        <meta property="twitter:title" content={title} key="twitter-title"/>
        <meta property="twitter:description" content={description} key="twitter-description"/>

        {/* OPEN GRAPH */}
        <meta property="og:type" content="website" key="og-type"/>
        <meta property="og:image" content="https://firebasestorage.googleapis.com/v0/b/portfolio-ashleyvanlaer.appspot.com/o/Schermafbeelding%202020-10-16%20om%2013.58.00.png?alt=media&token=fd23d559-9b93-4e3c-b5f9-502f0bde09e2" key="og-image"/>
        <meta property="og:title" content={title} key="og-title"/>
        <meta property="og:description" content={description} key="og-description"/>
        <meta property="og:site_name" content="Ashley Van Laer" key="og-site_name"/>
        <meta property="og:url" content={`https://ashleyvanlaer.be`} key="og-url"/>

        {/* DNS */}
        <link rel="canonical" href="https://ashleyvanlaer.be" key="canonical"/>
        <link rel="alternate" href="https://ashleyvanlaer.be" hrefLang="x-default" key="alternate"/>
      </Helmet>

      {!isMobile && <AnimatedCursor opacity={mouseOut ? 0 : 1} />}

      <CookieConsent
        disableButtonStyles={true}
        disableStyles={true}
        location="bottom"
        cookieName="CookieConsent"
        containerClasses={layoutStyles.cookie_container}
        buttonClasses={layoutStyles.cookie_btn}
        expires={150}
        acceptOnScroll={true}
        onAccept={({ acceptedByScrolling }) => {
          if (acceptedByScrolling) {
            // triggered if user scrolls past threshold
            setAccepted(true)
            setTimeout(() => {
              setAccepted(false)
            }, 3000)
          }
        }}
      >
        <p>
          By scrolling you accept our{" "}
          <Link to="/cookie-policy">cookie policy</Link>.
        </p>
      </CookieConsent>
      {accepted ? (
        <div className={layoutStyles.accepted}>
          <FeatherIcon icon="check" size="26" color="#ffffff" strokeWidth="2" />
        </div>
      ) : (
        <div className={layoutStyles.hide_accepted}>
          <FeatherIcon icon="check" size="26" color="#ffffff" strokeWidth="2" />
        </div>
      )}
      <div>{props.children}</div>
    </section>
  )
}
